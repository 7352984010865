import { graphql } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import * as React from "react";
import styled from "styled-components";
import Footer from "../../components/footer";
import Layout from "../../components/layout";
import Seo from "../../components/seo";
import sanitizeHtml from "sanitize-html";
import SocialLinksContainer from "../../components/socialLinksContainer";
import useSiteMetadata from "../../hooks/useSiteMetadata";
import "../../components/font-awesome.js";
import { useMediaQuery } from "react-responsive";
import MobileFooter from "../../components/mobile/mobileFooter";
import { Link } from "gatsby";

const Container = styled.div`
  display: flex;
  padding-top: 8rem;
  h1 {
    margin: 0 0 24px 0;
  }
`;

const MainContent = styled.div`
  padding: ${(props) => (props.mobile ? "4.5rem 3.2rem" : "4vw 2vw 4vw 10vw")};
  flex: 1;

  h1 {
    font-size: ${(props) => (props.mobile ? "2rem" : "")};
    font-weight: 500;
    color: var(--black);
  }

  .by {
    font-size: ${(props) => (props.mobile ? "1.4rem" : "1.8rem")};
    font-weight: 500;
    color: var(--blue);
    margin: 15px 0 30px 0;
  }

  .summary {
    font-size: 20px;
  }
`;

const SideBarContainer = styled.div`
  width: 24vw;
  right: 0;
  border-radius: 4px;
  border-left: solid 2px #ededed;
  overflow: auto;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  position: relative;
  z-index: 2;
  flex-shrink: 0;
`;

const SideBarContentWrapper = styled.div`
  width: 24vw;
  padding: 0 5rem 0 2.4rem;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  top: calc(4vw + 8rem);
  right: 0;
  position: fixed;

  border-left: solid 2px #373737;
`;

const SideBarContents = styled.div`
  width: 75%;
  display: flex;
  flex-direction: column;
  font-size: 14px;

  h3 {
    font-size: 2rem;
    font-weight: bold;
    color: var(--black);
    margin: 0;
  }

  p {
    font-size: 1.6rem;
    color: var(--accent);
    margin: 10px 0;
  }
`;

const StyledImg = styled(GatsbyImage)`
  object-fit: cover;
  width: 100%;
  border-radius: 24px;

  img {
    border-radius: 24px;
  }
`;

const SummaryInput = styled.div`
  padding-right: ${(props) => (props.mobile ? "" : "50px")};

  h2 {
    font-size: ${(props) => (props.mobile ? "1.8rem" : "2.4rem")};
  }

  figure {
    margin: 0;
  }

  h3 {
    font-size: ${(props) => (props.mobile ? "1.6rem" : "2rem")};
    font-weight: bold;
    margin: 0 0 2rem 0;
  }

  h4 {
    font-size: ${(props) => (props.mobile ? "1.6rem" : "1.8rem")};
    font-weight: normal;
  }

  p {
    font-size: ${(props) => (props.mobile ? "1.4rem" : "1.6rem")};
    color: var(--black);
    word-break: break-word;
  }

  img {
    max-width: 100%;
    max-height: 100%;
  }
`;

const RelatedNewsMobile = styled.div`
  margin-top: 4.5rem;

  h3 {
    font-size: 2rem;
    color: var(--black);
  }

  p {
    color: var(--ultramarine);
  }
`;

const LatestArticle = styled((props) => <Link {...props} />)`
  text-decoration: none;
  margin-top: 2rem;
  .latest-title {
    font-size: 1.6rem;
    color: var(--ultramarine);
    margin: ${(props) => (props.mobile ? "0 0 5px 0" : "0 0 1rem 0")};
  }

  .latest-date {
    color: var(--black);
    margin: 0;
  }
`;

const LatestArticleWrapper = styled.div`
  margin-top: 2rem;
`;

const SeoWrapper = styled.div``;

function StrapiArticle(props) {
  const article = props.data.strapiArticles;
  const latestNews = props.data.allStrapiArticles;
  const { siteName } = useSiteMetadata();
  const description = sanitizeHtml(article.summary).replace(/<[^>]+>/g, "");
  const isMobile = useMediaQuery({ query: "(max-width: 450px)" });
  const articleSummary = article.summary.replaceAll(
    'src="/',
    `src="https://api.nearshore.pt/`
  );
  return (
    <SeoWrapper>
      <Seo
        title={siteName + " | " + article.title}
        description={description}
        author={article.by}
        article={true}
        image={
          article.image[0].localFile.childImageSharp.gatsbyImageData.images
            .fallback.src
        }
      />
      <Layout>
        <Container>
          {!isMobile && <SocialLinksContainer arrow={true} />}
          <MainContent mobile={isMobile} className="main_content">
            <h1>{article.title}</h1>
            <StyledImg
              alt={`Picture for ${article.title} article`}
              image={article.image[0].localFile.childImageSharp.gatsbyImageData}
            />
            <p className="by">
              By {article.by} • {article.published_at.substring(0, 10)}
            </p>
            {/* Sanitizing html to prevent script injection attacks */}
            <SummaryInput
              mobile={isMobile}
              dangerouslySetInnerHTML={{
                __html: articleSummary,
              }}
            />
            {isMobile && (
              <RelatedNewsMobile>
                <h3>Latest news</h3>
                {latestNews ? (
                  latestNews.edges.map((article) => (
                    <LatestArticleWrapper>
                      <LatestArticle
                        mobile={isMobile}
                        to={`/news/${article.node.slug}`}
                      >
                        <p className="latest-title">{article.node.title}</p>
                        <p className="latest-date">
                          {article.node.published_at.substring(0, 10)}
                        </p>
                      </LatestArticle>
                    </LatestArticleWrapper>
                  ))
                ) : (
                  <p>No more news at the moment</p>
                )}
              </RelatedNewsMobile>
            )}
          </MainContent>
          {!isMobile && (
            <SideBarContainer className="news_sidebar">
              <SideBarContentWrapper>
                <SideBarContents>
                  <h3>Latest news</h3>
                  {latestNews ? (
                    latestNews.edges.map((article) => (
                      <LatestArticle to={`/news/${article.node.slug}`}>
                        <p className="latest-title">{article.node.title}</p>
                        <p className="latest-date">
                          {article.node.published_at.substring(0, 10)}
                        </p>
                      </LatestArticle>
                    ))
                  ) : (
                    <p>No more news at the moment</p>
                  )}
                </SideBarContents>
              </SideBarContentWrapper>
            </SideBarContainer>
          )}
        </Container>
        {!isMobile && <Footer />}
        {isMobile && <MobileFooter />}
      </Layout>
    </SeoWrapper>
  );
}

export default StrapiArticle;

export const query = graphql`
  query ($id: String) {
    strapiArticles(id: { eq: $id }) {
      id
      summary
      title
      published_at
      by
      image {
        localFile {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
    }
    allStrapiArticles(
      limit: 3
      sort: { order: DESC, fields: published_at }
      filter: { id: { ne: $id } }
    ) {
      edges {
        node {
          id
          slug
          title
          published_at
        }
      }
    }
  }
`;
